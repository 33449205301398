import React from 'react';
import { graphql } from 'gatsby';
import Layout from 'components/Layout';

const AboutPage = ({data: { markdownRemark: { html }}}) => {
  return (
    <Layout pageName="À propos" className="about">
      <div className="about-container">
        <div className="about-container-text" >
          <h1>À propos</h1>
          <div dangerouslySetInnerHTML={{ __html: html }}></div>
        </div>
      </div>
    </Layout>
  );
};
export const aboutQuery = graphql`
{
  markdownRemark(
    fileAbsolutePath: {
      regex: "/content/about/"
    }
  ) {
    html
  }
}
`
export default AboutPage;